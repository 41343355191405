<template>
    <div class="upload-container">
      <h1>Cargar Archivo</h1>
  
      <form @submit.prevent="uploadFile" enctype="multipart/form-data">
        <div class="form-group">
          <label for="folio">Folio:</label>
          <input type="text" v-model="folio" id="folio" required />
        </div>
  
        <div class="form-group">
          <label for="etapa">Etapa:</label>
          <select v-model="etapa" id="etapa" required>
            <option value="" disabled>Seleccione una etapa</option>
            <option value="ALTA">ALTA</option>
            <option value="EN DIAGNÓSTICO">EN DIAGNÓSTICO</option>
            <option value="PREPARANDO COTIZACIÓN">PREPARANDO COTIZACIÓN</option>
            <option value="COTIZACIÓN EN ESPERA DE AUTORIZACIÓN">COTIZACIÓN EN ESPERA DE AUTORIZACIÓN</option>
            <option value="EN SERVICIO">EN SERVICIO</option>
            <option value="EN PROCESO DE EMBARQUE">EN PROCESO DE EMBARQUE</option>
            <option value="ENVIADO">ENVIADO</option>
            <option value="ENTREGADO">ENTREGADO</option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="archivo">Archivo:</label>
          <input type="file" @change="onFileChange" id="archivo" required />
        </div>
  
        <button type="submit" class="submit-btn">Subir Archivo</button>
  
        <p v-if="message" class="message">{{ message }}</p>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        folio: '',
        etapa: '',
        archivo: null,
        message: '',
      };
    },
    methods: {
      onFileChange(e) {
        this.archivo = e.target.files[0];
      },
      async uploadFile() {
        const formData = new FormData();
        formData.append('folio', this.folio);
        formData.append('etapa', this.etapa);
        formData.append('archivo', this.archivo);
  
        try {
          const response = await axios.post('http://localhost:1000/api/carga-archivos', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
               Authorization: `Bearer ${localStorage.getItem('token')}`              
            },
          });
          this.message = 'Archivo subido correctamente';
          console.log(response.data);
        } catch (error) {
          console.error('Error al subir archivo:', error);
          this.message = 'Error al subir archivo';
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .upload-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
  
  input[type="text"],
  select,
  input[type="file"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
  .message {
    text-align: center;
    font-weight: bold;
    color: #28a745;
    margin-top: 15px;
  }
  
  @media (max-width: 768px) {
    .upload-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.2rem;
    }
  
    input[type="text"],
    select,
    input[type="file"] {
      font-size: 0.8rem;
      padding: 6px;
    }
  
    .submit-btn {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
  </style>
  