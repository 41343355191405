<template>
  <v-container>
    <v-card>
      <v-card-title>
        Catálogo de Mantenimientos
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openDialog(null)">Nuevo Mantenimiento</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="mantenimientos"
            :items-per-page="5"
            class="elevation-1 custom-table"
            @click:row="openDialog"
        ></v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="editedItem.nombre_proceso" label="Nombre Proceso" required></v-text-field>
            <v-text-field v-model="editedItem.id_etapa" label="ID Etapa" required></v-text-field>
            <v-text-field v-model="editedItem.id_estado" label="ID Estado" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dialog: false,
      mantenimientos: [],
      editedIndex: -1,
      editedItem: {
        id_record: null,
        nombre_proceso: '',
        id_etapa: '',
        id_estado: ''
      },
      defaultItem: {
        id_record: null,
        nombre_proceso: '',
        id_etapa: '',
        id_estado: ''
      },
      headers: [
        { text: 'ID Record', value: 'id_record' },
        { text: 'Nombre Proceso', value: 'nombre_proceso' },
        { text: 'ID Etapa', value: 'id_etapa' },
        { text: 'ID Estado', value: 'id_estado' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ]
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Mantenimiento' : 'Editar Mantenimiento';
    }
  },
  methods: {
    fetchData() {
      axios
          .get('http://72.167.42.24:1000/api/mantenimientos', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            this.mantenimientos = response.data;
          })
          .catch(error => {
            console.error('Error al obtener mantenimientos:', error);
          });
    },
    openDialog(item) {
      if (item) {
        this.editedIndex = this.mantenimientos.indexOf(item);
        this.editedItem = Object.assign({}, item);
      } else {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    saveItem() {
      if (this.editedIndex > -1) {
        axios
            .put(`http://72.167.42.24:1000/api/mantenimientos/${this.editedItem.id_record}`, this.editedItem, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then(response => {
              Object.assign(this.mantenimientos[this.editedIndex], response.data);
              this.closeDialog();
            })
            .catch(error => {
              console.error('Error al actualizar mantenimiento:', error);
            });
      } else {
        axios
            .post('http://72.167.42.24:1000/api/mantenimientos', this.editedItem, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then(response => {
              this.mantenimientos.push(response.data);
              this.closeDialog();
            })
            .catch(error => {
              console.error('Error al crear mantenimiento:', error);
            });
      }
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style scoped>
.custom-table .v-data-table__wrapper tbody tr.custom-row {
  border-bottom: none !important;
}

.custom-table .v-data-table__wrapper tbody td {
  border-bottom: none !important;
}
.elevation-1 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
</style>
