<template>

  <v-app>

    <MenuView :role="userRole || ''" /> <!-- Asegúrate de utilizar el componente MenuView -->
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'; // Importar mapState para obtener el estado del store
import MenuView from './MenuView.vue'; // Asegúrate de importar correctamente el componente MenuView

export default {
  name: 'DashboardView',
  components: {
    MenuView // Asegúrate de incluir MenuView en la lista de componentes utilizados
  },
  computed: {
    ...mapState(['rol']), // Mapear el estado del rol desde Vuex store
    userRole() {
      return this.rol; // Definir userRole como el rol desde el estado
    }
  }
};
</script>

<style>
/* Estilos opcionales para DashboardView.vue */
</style>
