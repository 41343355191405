<template>
  <v-container>
    <div v-if="loadingReport" class="overlay">
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div class="containerHeader">
    <div class="section">
      <div class="section-content">
         <div class="icon-container" :style="{width: '40px', 
          height: '40px', 
          backgroundColor: '#FF0000', 
          backgroundImage: `url(/img/ESPERA.png)`, 
          backgroundSize: '30px 30px', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center'}"></div>
        <span class="section-text"><b>POR INICIAR</b></span>
      </div>
    </div>
    <div class="section">
      <div class="section-content">
        <div class="icon-container" :style="{backgroundColor: '#FF6D00', backgroundImage: `url(/img/PROCESO.png)`,backgroundSize: '30px 30px',
                 backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}"></div>
        <span class="section-text"><b>EN PROCESO</b></span>
      </div>
    </div>
    <div class="section">
      <div class="section-content">
        <div class="icon-container" :style="{backgroundColor: '#4CAF52', backgroundImage: `url(/img/TERMINADO.png)`,backgroundSize: '30px 30px',
                 backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}"></div>
        <span class="section-text"><b>TERMINADO</b></span>
      </div>
    </div>
  </div>
    <div class="full-width-background">
    <!-- Contenido aquí -->
    CLIENTE: 	{{this.$route.params.clientName}}, FOLIO: {{this.$route.params.folio}}, EQUIPO: {{this.$route.params.equipo}}
  </div>
    <!-- Timeline para mostrar el progreso de la orden de servicio y comunicación -->
    <div class="timeline-headers">
      <div class="timeline-header">
        ESTATUS
      </div>
      <div class="timeline-header-etapa">
        ETAPA
      </div>
      <div class="timeline-header-archivos">
        ARCHIVOS DE CONSULTA Y DESCARGA
      </div>
    </div>
    <div class="timeline-wrapper">         
        <v-timeline dense>
          <v-slide-x-transition group> 
            <v-timeline-item v-for="(event, index) in detailData" :key="index" :color="getDotColor(event.nombre_estado)">
              <!-- Contenido del Timeline -->
              <template v-slot:icon>
                <div class="icon-container">
                  <div class="status-icon-container">
                    <v-icon :class="getIconClass(event.nombre_estado)">{{ getIconClass(event.nombre_estado) }}</v-icon>
                  </div>
                </div>
                <div class="additional-icon-container">
                   <!--v-icon class="additional-icon" >{{ getAdditionalIcon(event.nombre_estado) }}</v-icon-->
                   <img :src="getAdditionalIcon(event.etapa)" alt="Estado Icono" class="additional-icon" />
                </div>
                <div class="additional-icon-container-icon" :style="getIconStyle(event.nombre_estado)">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </template>
                <!-- Encabezado -->
              <div class="timeline-item-content">
                 <!-- Flex container for content and icons -->
          <div class="timeline-content-flex">
             <!-- Colocar estos elementos a la izquierda -->
  <div class="left-content">
    <div>{{ event.etapa }}</div>   
    <div> {{ formatDate(event.fecha_actividad ) }}  </div> 
  </div>
  <!-- Colocar íconos a la derecha -->
  <div class="archivo-icons">
    <!--v-icon color="primary">mdi-file-document</v-icon-->
    <div class="custom-icon"></div>
    <!-- Puedes agregar más íconos aquí -->
  </div>
          </div>
              </div>
           
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>      

      </div>
      <div class="info-message-1" style="display: flex; flex-direction: column; align-items: flex-start;">
    <div style="display: flex; ">
        <!--v-icon>mdi-information</v-icon>&nbsp;
        <b>Los archivos descargables estarán disponibles 30 días a partir de su fecha de finalización</b-->
          <div class="info-icon"></div>&nbsp;<b>Los archivos descargables estarán disponibles 30 días a partir de su fecha de finalización</b>
    </div>
    <v-btn type="button" class="v-btn-geen" color="#009496" text-color="white" @click="regresar" style="margin-top: 10px; align-self: center;">
        Regresar
    </v-btn>
</div>







   
  <div class="info-message color-text-green">
      Si tienes dudas puedes contactarte al siguiente número y/o correo o ponerte en contacto con tu asesor de ventas
    </div> 
    <div class="contact-info">
  <p>Victor Sánchez</p>
  <p>v.sanchez@grupocormac.com</p>
  <p>Tel: 818333 5535 ext.230</p>
</div>
  </v-container>
  
</template>


<script>
import axios from 'axios';
export default {
  name: 'TimeLineView',
  data() {
    return {
      modalOpen: false,
      selectedFolio: '',
      tipo: '',
      equipo: '',
      nombre: '',
      noSerie: '',
      loadingReport: true,
      detailHeaders: [
        { text: 'Etapa', align: 'start', sortable: false, value: 'etapa' },
        { text: 'Estado', value: 'nombre_estado' },
        { text: 'Fecha', value: 'fecha_actividad' },
        { text: 'Hora', value: 'hora_actividad' },
        { text: 'Comunicación Cliente', value: 'comunicacion_cliente' },
      ],
      detailData: [/*
        { etapa: 'ALTA', nombre_estado: 'CONCLUIDO', fecha_actividad: '2024-06-10', hora_actividad: '10:24:21', comunicacion_cliente: '', timeline: [] },
        { etapa: 'COTIZADO EN ESPERA DE AUTORIZACIÓN', nombre_estado: 'EN PROCESO', fecha_actividad: '2024-06-14', hora_actividad: '16:19:32', comunicacion_cliente: 'Cliente espera confirmación de la cotización.', timeline: [] },
        { etapa: 'EN DIAGNÓSTICO', nombre_estado: 'CONCLUIDO', fecha_actividad: '2024-06-14', hora_actividad: '16:04:10', comunicacion_cliente: '', timeline: [] },
        { etapa: 'EN SERVICIO', nombre_estado: 'CONCLUIDO', fecha_actividad: '2024-06-14', hora_actividad: '16:25:28', comunicacion_cliente: '', timeline: [] },
      */],
      input: '',
      timeline: [],
      nonce: 0,
    };
  },
  methods: {
    formatDate(dateString) {
    // Asegúrate de que la cadena no esté vacía
    if (!dateString) return '';

    // Crear un objeto Date a partir de la cadena
    const date = new Date(dateString);

    // Extraer el día, mes y año
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = date.getFullYear();

    // Formatear en dd/MM/yyyy
    return `${day}/${month}/${year}`;
  },
    comment() {
      const now = new Date();
      const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const date = now.toLocaleDateString();
      this.timeline.push({
        id: this.nonce++,
        etapa: 'Nuevo comentario',
        nombre_estado: '',
        fecha_actividad: date,
        hora_actividad: time,
        comunicacion_cliente: this.input,
      });
      this.input = '';
    },
    regresar() {
      this.$router.push({ name:'ConsultaFoliosView'});
    },
    getStageColor(etapa) {
      switch (etapa.toLowerCase()) {
        case 'alta':
          return 'purple';
        case 'en diagnóstico':
          return 'blue';
        case 'terminado':
          return 'green';
        default:
          return 'grey';
      }
    },
    getDotColor(estado) {
      switch (estado) {
        case 'CONCLUIDO':
          return 'green';
        case 'EN PROCESO':
          return 'orange';
        case 'POR INICIAR':
          return 'blue';
        default:
          return 'grey';
      }
    },
    getIconClass(estado) {
      switch (estado) {
        case 'CONCLUIDO':
          return 'icon-concluido';
        case 'EN PROCESO':
          return 'icon-en-proceso';
        case 'POR INICIAR':
          return 'icon-por-iniciar';
        default:
          return '';
      }
    }, getAdditionalIcon(nombre_estado) {
      // Devuelve la ruta de la imagen según el estado
  switch (nombre_estado) {
    case 'ALTA':
      return '/img/ALTA.png'; 
    case 'EN DIAGNÓSTICO':
      return '/img/DIAGNOSTICO.png';
    case 'PREPARANDO COTIZACIÓN':
      return '/img/PREPARANDO_COTIZACIO╠üN.png';
	case 'COTIZADO EN ESPERA DE AUTORIZACIÓN':
      return '/img/COTIZACIO╠üN_ESPERA.png';
	case 'EN SERVICIO':
      return '/img/SERVICIO.png';
	case 'EN PROCESO DE EMBARQUE':
      return '/img/PROCESO_ EMBARQUE.png';
	case 'ENVIADO':
    return '/img/ENVIADO.png';
	case 'ENTREGADO':
     return '/img/ENTREGADO.png';
    default:
      return '/public/img/default.png'; 
  }
    },  getIconStyle(nombreEstado) {
      let iconPath = '';
      switch (nombreEstado) {
        case 'CONCLUIDO':
          iconPath = '/img/TERMINADO.png';  // Cambia a la imagen correspondiente
          break;
        case 'EN PROCESO':
          iconPath = '/img/ESPERA.png';  // Cambia a la imagen correspondiente
          break;                 
        default:
          iconPath = '/img/INFO.png';  // Imagen por defecto
      }
      
      return {
        backgroundImage: `url(${iconPath})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    },
  },
  created() {
    const folio = this.$route.params.folio;    
    const tipo = this.$route.params.tipo;
    const equipo = this.$route.params.equipo;
    const nombre = this.$route.params.nombre;
    const no_serie = this.$route.params.no_serie;
    const cve_cte=this.$route.params.cve_cte;
    const cve_entre=this.$route.params.cve_entre;
    const clientName =this.$route.params.clientName

    console.log(':::::::::::', folio);
    console.log('::::::::::::', tipo);
    console.log('::::::::::::', equipo);
    console.log('::::::::::::', nombre);
    console.log('::::::::::::', no_serie);
    console.log('::::::::::::', clientName);
    
    const url = `http://72.167.42.24:1000/api/monitor-orders/${cve_cte}/${cve_entre}/${folio}`;
      axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            console.log(response.data)
            this.detailData = response.data;
            this.modalOpen = true;
          })
          .catch(error => {
            console.error('Error fetching folio details:', error);
          }).finally(() => {
          this.loadingReport = false; // Desactiva el spinner cuando la llamada termina
        });

    // Simulación de carga inicial de datos en el timeline
    /*this.detailData.forEach((item) => {
      this.timeline.push({
        id: this.nonce++,
        etapa: item.etapa,
        nombre_estado: item.nombre_estado,
        fecha_actividad: item.fecha_actividad,
        hora_actividad: item.hora_actividad,
        comunicacion_cliente: item.comunicacion_cliente,
        timeline: [], // Agrega un timeline vacío para cada item
      });
    });*/

  },
};
</script>

<style scoped>
.icon-concluido {
  color: green;
}
.icon-en-proceso {
  color: orange;
}
.icon-por-iniciar {
  color: blue;
}
.timeline-wrapper {
     display: flex;
    justify-content: center;
    margin: 0 auto;
    /* width: 100%; */
    margin-left: -59px;
}
.status-icon-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el ícono del estado dentro del círculo */
  width: 40px; /* Ajusta el tamaño del contenedor del ícono del estado */
  height: 40px;
  background-color: var(--v-theme-primary); /* Color de fondo opcional */
  border-radius: 50%; /* Hace el contenedor circular */
}
.additional-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinea el ícono adicional a la izquierda */
  margin-right: 140px; /* Espacio entre el ícono adicional y el ícono del estado */
}
.additional-icon-container-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinea el ícono adicional a la izquierda */
  margin-right: 190px; /* Espacio entre el ícono adicional y el ícono del estado */
}
.additional-icon {
  position: absolute;
  left: 0; /* Alinea el ícono adicional a la izquierda */
  margin-left: -30px; /* Espacio entre el ícono adicional y el borde izquierdo */
 }
 .timeline-item-content {
  margin-left: 70px; /* Espacio entre el icon-container y el contenido del timeline-item */
  /*text-align: center;*/
}
.info-icon {
  width: 30px; /* Ajusta el tamaño del contenedor */
  height: 30px; /* Ajusta el tamaño del contenedor */
  margin-right: 10px; /* Espacio entre el icono y el texto */
  background: url('/public/img/INFO.png') no-repeat center center;
  /*background-size: 70%; /* Ajusta el tamaño de la imagen de fondo para que encaje bien dentro del círculo */
  background-color: yellow; /* Color de fondo del contenedor */
  border-radius: 50%; /* Hace el contenedor redondo */
  display: inline-block; /* Para asegurar el correcto comportamiento de la alineación */
  line-height: 0; /* Elimina cualquier espacio extra generado por la altura de la línea */
  padding: 14px; /* Añade un pequeño espacio alrededor del icono */
  box-sizing: border-box; /* Asegura que el padding no cambie el tamaño total del elemento */
  
}
.info-message-1 {
  display: flex; /* Usar flexbox para alinear los elementos hijos */
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  font-size: 16px; /* Tamaño de la fuente del texto */
  color: #333; /* Color del texto */
  padding: 3px;
  border-radius: 5px; /* Esquinas redondeadas opcionales */
  background-color: white; /* Fondo opcional */
  margin: 60px auto; /* Centra el div horizontalmente en su contenedor padre */
  text-align: center; /* Centra el texto dentro del div */
  max-width: 600px; /* Ancho máximo opcional para controlar el tamaño */
}
.info-message-2 {
  display: flex; /* Usar flexbox para alinear los elementos hijos */
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  font-size: 16px; /* Tamaño de la fuente del texto */
  color: #333; /* Color del texto */
  padding: 10px;
  border-radius: 5px; /* Esquinas redondeadas opcionales */
  background-color: white; /* Fondo opcional */
  margin: 60px auto; /* Centra el div horizontalmente en su contenedor padre */
  text-align: center; /* Centra el texto dentro del div */
  max-width: 600px; /* Ancho máximo opcional para controlar el tamaño */
}
.info-message {
  display: flex; /* Usar flexbox para alinear los elementos hijos */
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  font-size: 16px; /* Tamaño de la fuente del texto */
  color: #333; /* Color del texto */
  padding: 10px;
  border-radius: 5px; /* Esquinas redondeadas opcionales */
  background-color: white; /* Fondo opcional */
  margin: 0 auto; /* Centra el div horizontalmente en su contenedor padre */
  text-align: center; /* Centra el texto dentro del div */
  max-width: 600px; /* Ancho máximo opcional para controlar el tamaño */
}
.color-text-green {
  color: #009496;
}

.contact-info {
  background-color: #009496;
  color: white;
  padding: 20px;
  border-radius: 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;   /* Para que no sea tan ancho */
  margin: 10px auto; /* Centrar y darle un margen al final */
  
}
.contact-info p {
  margin: 0; /* Eliminar margen por defecto en los párrafos */
  line-height: 1.5; /* Espaciado entre líneas */
  text-align: center;
}

.full-width-background {
  width: 100%; /* Asegura que el div se extienda de lado a lado */
  background-color: #009496; /* Color de fondo */
  padding: 20px; /* Espaciado interno opcional */
  box-sizing: border-box; /* Incluye padding y border en el width y height */
  color: white; /* Color del texto dentro del div */
}
.containerHeader {
  display: flex; /* Utiliza Flexbox para alinear los elementos en línea */
  width: 80%; /* Ocupa el 80% del ancho de la pantalla */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  background-color: #f0f0f0; /* Fondo opcional para el contenedor */
  box-sizing: border-box; /* Incluye padding y border en el width y height */
}

.section {
  flex: 1; /* Hace que cada sección ocupe un espacio igual */
  padding: 20px; /* Espaciado interno opcional */
  background-color: white; /* Color de fondo para cada sección */
  color: black; /* Color del texto dentro de cada sección */
  text-align: center; /* Centra el texto horizontalmente */
}
.section-content {
  display: flex; /* Utiliza Flexbox para alinear el ícono y el texto */
  align-items: center; /* Centra verticalmente el ícono y el texto */
}

.section-icon {
  font-size: 24px; /* Tamaño del ícono */
  margin-right: 10px; /* Espacio entre el ícono y el texto */
}

.section-text {
  font-size: 16px; /* Tamaño del texto */
}
.timeline-headers {
  display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 10px;
    width: 83%;
    margin: 0 auto;
    margin-right: 130px;
}

.timeline-header {
  text-align: right; /* Alinea el texto a la derecha */
  font-weight: bold;
  font-size: 18px;
  color: black; /* Color de los encabezados */
  padding: 10px; 
}
.timeline-header-etapa {
  text-align: left; /* Alinea el texto a la derecha */
  font-weight: bold;
  font-size: 18px;
  color: black; /* Color de los encabezados */
  padding: 10px; 
  margin-left: 70px;
}
.timeline-header-archivos {
  text-align: left; /* Alinea el texto a la derecha */
  font-weight: bold;
  font-size: 18px;
  color: black; /* Color de los encabezados */
  padding: 10px; 
  margin-left: 50px;
}
.v-btn-geen {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 30px; 
  color: white !important;
  background-color: #009496; 
  border: none;
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  z-index: 9999; /* Asegúrate de que esté por encima de todo */
}
.additional-icon {
  width: 40px;  /* Ajusta el tamaño según necesites */
  height: 40px;
  object-fit: contain; /* Para que la imagen mantenga su proporción */
}
.timeline-content-flex {
  display: flex;
  justify-content: space-between; /* Esto separa los elementos */
  align-items: center; /* Alinea verticalmente los elementos */
  padding: 10px 0; /* Añadir algo de espacio arriba y abajo */
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.archivo-icons {
  display: flex;
    justify-content: flex-end;
    margin-left: 115px;
}

.custom-icon {
  width: 24px; /* Ajusta el tamaño del ícono según sea necesario */
  height: 24px; /* Ajusta el tamaño del ícono según sea necesario */
  background-image: url('/public/img/DESCARGA.png'); /* Ruta al archivo PNG en el directorio public */
  background-size: contain; /* Ajusta la imagen para que se ajuste dentro del contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; /* Centra la imagen en el contenedor */
}

.icon-container {
  width: 40px; /* Ancho del contenedor circular */
  height: 40px; /* Alto del contenedor circular */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

</style>
