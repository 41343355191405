import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '../src/store/store.js';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';


Vue.config.errorHandler = function (err, vm, info) {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`);
  // Aquí puedes agregar lógica personalizada para manejar errores,
  // como enviar el error a un servicio de monitoreo o mostrar una notificación al usuario.
};


Vue.use(Vuetify);
export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
});

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app');
