import Vue from 'vue';
import Router from 'vue-router';
import LoginView from '@/components/LoginView.vue';
import DashboardView from '@/components/DashboardView.vue';
import ConsultaFoliosView from '@/components/ConsultaFoliosView.vue';
import CatalogoMantenimientoView from '@/components/CatalogoMantenimientoView.vue';
import UsuariosView from '@/components/UsuariosView.vue';
import ReportesView from '@/components/ReportesView.vue';
import TimeLineView from '@/components/TimeLineView.vue';
import CargaArchivos from '@/components/CargaDeArchivos.vue';



Vue.use(Router);

const router = new Router({
    routes: [
        { path: '/login', name: 'LoginView', component: LoginView },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: DashboardView,
            children: [
                { path: 'consulta-folios', name: 'ConsultaFoliosView', component: ConsultaFoliosView },
                { path: 'time-line/:folio/:tipo', name: 'TimelineView', component: TimeLineView },
                { path: 'catalogo-mantenimiento', name: 'CatalogoMantenimientoView', component: CatalogoMantenimientoView },
                { path: 'usuarios', name: 'UsuariosView', component: UsuariosView },
                { path: 'reportes', name: 'ReportesView', component: ReportesView },
                { path: 'cargaarchivos', name: 'ArchivosView', component: CargaArchivos }
            ]
        },
        { path: '*', redirect: '/login' }
    ]
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    console.log('Token:', token, 'Current path::',to.path);
    if (to.path !== '/login' && !token) {
        next('/login');
    } else if (to.path === '/login' && token) {
        next('/dashboard'); // Redirige a dashboard si el token existe
    } else {
        next();
    }
});

export default router;
