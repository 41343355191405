<template>
  <v-app>
    <v-app-bar app class="custom-header" dark>
      <!-- Contenido de la barra de navegación, como logotipos, botones, etc. -->
      <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://grupocormac.com/"
          transition="scale-transition"
          width="40"
      />

      <v-spacer></v-spacer>

    </v-app-bar>

    <!-- Menú de navegación horizontal, visible solo si no está en la página de login -->
    <v-toolbar v-if="$route.name !== 'LoginView'" dense color="primary" dark>
      <MenuView :role="userRole ||''" />
    </v-toolbar>

    <v-main>
      <!-- Contenido principal, donde se renderizan las vistas -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import MenuView from './components/MenuView.vue';  // Importa el componente MenuView

export default {
  name: 'App',
  components: {
    MenuView
  },

  computed: {
    ...mapState(['loggedIn','userRole']),
  },

  watch: {
    loggedIn(newValue) {
      // Redirigir al login si no está autenticado
      if (!newValue && this.$router.currentRoute.name !== 'LoginView') {
        this.$router.push({name: 'LoginView'});
      }
    },
  },
};
</script>

<style>
/* Estilos adicionales según necesidades */
.custom-header {
  background-color: #009496 !important;
}
</style>
