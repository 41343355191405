<template>
   <v-container class="d-flex align-center justify-center" fill-height>
    <v-card class="pa-5 login-card">
    <v-form @submit.prevent="submitLogin">
      <div>
  <label for="username" style="display: none;">Usuario:</label>
  <input
    type="text"
    id="username"
    v-model="username"
    placeholder="Usuario"
    required
    style="width: 100%; padding: 10px; background-color: #e0e0e0; border: none; border-radius: 0;"

  />
</div>

<div style="margin-top: 10px;">
  <label for="password" style="display: none;">Contraseña:</label>
  <input
    type="password"
    id="password"
    v-model="password"
    placeholder="Contraseña"
    required
    style="width: 100%; padding: 10px; background-color: #e0e0e0; border: none; border-radius: 0;"

  />
</div>

      <v-card-actions class="d-flex justify-center"><v-btn type="submit"  class="v-btn-geen" color="#009496" text-color="white">Entrar</v-btn></v-card-actions>
      <v-card-actions class="text-center justify-center">
              <div class="login-text">
                ¿Olvidaste usuario y/o contraseña?<br>
                Favor de enviar correo<br>
                a sistemas@grupocormac.com<br><br><br>
                ¿Aún no tienes usuario?<br>
                Favor de enviar correo a<br>
                sistemas@grupocormac.com
                 
              </div>
            </v-card-actions>
    </v-form>
    
  </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    ...mapActions(['login']),
    async submitLogin() {
      try {
        await this.login({ username: this.username, password: this.password });
      } catch (error) {
        console.error('Error al iniciar sesión:', error);
      }
    }
  }
};
</script>
<style scoped>
.v-btn-geen {
  padding: 15px 30px;  /* Ajusta el espaciado interno según sea necesario */
  font-size: 16px;
  margin: 0 10px;
  border-radius: 30px; 
  color: white !important;
  background-color: #009496; 
  border: none;
  cursor: pointer;

  /* Tamaño fijo del botón */
  width: 200px;  /* Ajusta el ancho según sea necesario */
  height: 100px;  /* Ajusta la altura según sea necesario */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ajustes para centrar el cuadro de login */
.v-container {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Tamaño 50% más grande */
  width: 30vw;
  height: 60vh;
  /* Borde más grande */
  border: 5px solid #009496; /* Grosor de 5px y color verde */
}
.login-text {
  font-size: 14px;
  color: #009496; /* Ajusta el color del texto */
}

.login-text a {
  color: #009496;
  text-decoration: none;
}
</style>

