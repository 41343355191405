import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loggedIn: false,
        accessToken: null,
        userRol: '',
        cve_cte: null,
        cve_entre: null,
        id: null,
    },
    mutations: {
        SET_LOGGED_IN(state, loggedIn) {
            state.loggedIn = loggedIn;
        },
        SET_ACCESS_TOKEN(state, accessToken) {
            state.accessToken = accessToken;
        },
        SET_ROL(state, rol) {
            state.rol = rol;
        },
        SET_USER_INFO(state, { cve_cte, cve_entre }) {
            state.cve_entre = cve_entre;
            state.cve_cte = cve_cte;
        }
    },
    actions: {
        login({ commit }, { username, password }) {
            return new Promise((resolve, reject) => {
                axios.post('http://72.167.42.24:1000/api/auth/login', {
                    username,
                    password
                })
                    .then(response => {
                        const { accessToken, rol, cve_cte, cve_entre } = response.data;
                        commit('SET_ACCESS_TOKEN', accessToken);
                        commit('SET_ROL', rol);
                        commit('SET_USER_INFO', {cve_cte, cve_entre});
                        commit('SET_LOGGED_IN', true);
                        localStorage.setItem('token', accessToken);
                        router.push({ name: 'Dashboard' });
                        resolve(response);
                    })
                    .catch(error => {
                        console.error('Error al iniciar sesión:', error);
                        reject(error);
                    });
            });
        },
        logout({ commit }) {
            commit('SET_LOGGED_IN', false);
            commit('SET_ACCESS_TOKEN', null);
            commit('SET_ROL', null);
            commit('SET_USER_INFO', {cve_cte:null, cve_entre:null});
            localStorage.removeItem('token');
            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }

        }
    },
});
